import { Injectable, inject } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { of, switchMap, tap } from 'rxjs';
import { AppMenuService } from './app-menu.service';
import { Menu, MenuService } from './menu.service';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  private readonly auth = inject(AuthService);
  private readonly appMenuService = inject(AppMenuService);
  private readonly menuService = inject(MenuService);
  private readonly permissonsService = inject(NgxPermissionsService);
  private readonly rolesService = inject(NgxRolesService);

  /**
   * Load the application only after get the menu or other essential informations
   * such as permissions and roles.
   */
  load() {
    this.setMenu([]);

    return new Promise<void>((resolve, reject) => {
      this.auth
        .isAuthenticated$
        .pipe(
          switchMap((isAuth) => isAuth ? this.appMenuService.GetMenu$() : of([])),
          tap(menu => this.setMenu(menu))
        )
        .subscribe({
          next: () => resolve(),
          error: () => resolve(),
        });
    });
  }

  private setMenu(menu: Menu[]) {
    this.menuService.addNamespace(menu, 'menu');
    this.menuService.set(menu);
  }

  private setPermissions(user: User) {
    // In a real app, you should get permissions and roles from the user information.
    const permissions = ['canAdd', 'canDelete', 'canEdit', 'canRead'];
    this.permissonsService.loadPermissions(permissions);
    this.rolesService.flushRoles();
    this.rolesService.addRoles({ ADMIN: permissions });

    this.rolesService.getRole('');

    // Tips: Alternatively you can add permissions with role at the same time.
    // this.rolesService.addRolesWithPermissions({ ADMIN: permissions });
  }
}
