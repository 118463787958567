import { inject, Injectable } from '@angular/core';
import { getProject } from '@app/shared/services/project-store/project-store.selectors';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { Menu } from './menu.service';

@Injectable({
  providedIn: 'root',
})
export class AppMenuService {
  private _store = inject(Store);

  private _defaultMenu: Menu[] = [
    {
      route: 'dashboard',
      name: 'dashboard',
      type: 'link',
      icon: 'dashboard',
      // "badge": {
      //   "color": "red-50",
      //   "value": "5"
      // }
    },
    {
      route: 'workloads',
      name: 'workloads',
      type: 'link',
      icon: 'build',
    },
    {
      route: 'studies',
      name: 'studies',
      type: 'link',
      icon: 'folder_open',
    },
  ];

  GetMenu(): Menu[] {
    const clonedMenu: Menu[] = [];
    this._defaultMenu.forEach((val) => clonedMenu.push(Object.assign({}, val)));
    return clonedMenu;
  }

  GetMenu$(): Observable<Menu[]> {
    return this._store.pipe(
      select(getProject),
      map((project) => {
        if (project == null || project == undefined) {
          return [];
        } else {
          const clonedMenu: Menu[] = [];
          this._defaultMenu.forEach((val) =>
            clonedMenu.push(Object.assign({}, val)),
          );
          return clonedMenu;
        }
      }),
    );
  }
}
